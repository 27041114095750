import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Intro from '../components/intro';
import Rituale from '../components/rituale';
import Coaches from '../components/coaches';
import Journey from '../components/journey';
import Community from '../components/community';
import Anmeldung from '../components/anmeldung';
import References from '../components/references';

const IndexPage = ({
  data: {
    wordpressPage: {
      acf: {
        intro__cta,
        intro__title,
        intro__content,
        intro__background_image,
        rituale__title,
        rituale__content,
        rituale__image,
        rituale__box,
        rituale__features,
        rituale__title_2,
        rituale__content_2,
        rituale__title_3,
        coaches__coach,
        coaches__title,
        ref__title,
        ref__ref,
        journey__title,
        journey__image,
        journey__section,
        community__title,
        community__content,
        community__image,
        anmeldung__title,
        anmeldung__content,
        anmeldung__contact,
      },
    },
  },
}) => (
  <Layout>
    <Intro
      cta={intro__cta}
      title={intro__title}
      content={intro__content}
      background={intro__background_image}
    />
    <Rituale
      title={rituale__title}
      content={rituale__content}
      image={rituale__image}
      box={rituale__box[0]}
      title2={rituale__title_2}
      content2={rituale__content_2}
      title3={rituale__title_3}
      features={rituale__features}
    />
    <Coaches
      identifier="coaches"
      colorScheme={1}
      coaches={coaches__coach}
      title={coaches__title}
    />
    <Journey
      title={journey__title}
      image={journey__image}
      section={journey__section}
    />
    <Coaches
      identifier="references"
      colorScheme={2}
      coaches={ref__ref}
      title={ref__title}
    />
    <Community
      title={community__title}
      content={community__content}
      image={community__image}
    />
    <Anmeldung
      title={anmeldung__title}
      content={anmeldung__content}
      contact={anmeldung__contact}
    />
  </Layout>
);

export default IndexPage;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    wordpressPage(id: { eq: "e92f8152-259d-5ffb-8057-0a911e9af1bf" }) {
      acf {
        intro__title
        intro__content
        intro__background_image {
          sizes {
            large_width
            large_height
            large
          }
        }
        intro__cta {
          url
          target
          title
        }
        rituale__title
        rituale__content
        rituale__box {
          title
          content
        }
        rituale__image {
          alt
          sizes {
            medium_large_width
            medium_large_height
            medium_large
          }
        }
        rituale__features {
          title
          content
        }
        rituale__title_2
        rituale__content_2
        rituale__title_3
        coaches__title
        coaches__coach {
          title
          content
          image {
            alt
            sizes {
              medium_large_width
              medium_large_height
              medium_large
            }
          }
        }
        ref__title
        ref__ref {
          image {
            alt
            sizes {
              medium_large_width
              medium_large_height
              medium_large
            }
          }
          title
          content
        }
        journey__title
        journey__section {
          journey__title
          journey__image {
            alt
            sizes {
              medium_large
            }
          }
          journey__color
          journey__timeline {
            title
            content
          }
        }
        community__title
        community__content
        community__image {
          alt
          sizes {
            medium_large
          }
        }
        anmeldung__title
        anmeldung__content
        anmeldung__contact
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import ScrollArrow from './scrollArrow';

const Coaches = ({ identifier, colorScheme, coaches, title }) => (
  <section className={`coaches coaches__scheme-${colorScheme}`} id={identifier}>
    <div className="container">
      <h2 className="coaches__title">{title}</h2>
      {chunk(coaches, 2).map((row, i) => (
        <div
          key={`coaches-row-${i}`}
          className={`coaches__row row ${
            i === chunk(coaches, 2).length - 1 && coaches.length > 3
              ? 'lastrow'
              : ''
          } ${i === 0 ? '' : 'coaches__row--offset'}`}
        >
          {row.map((coach, j) => (
            <div
              key={`coach-${coach.title}`}
              className={`${
                j % 2 === 0
                  ? 'col-xs-12 col-md-6'
                  : 'col-xs-12 col-md-6 first-xs last-md'
              }`}
            >
              <div
                className={`box bleed bleed--${j % 2 === 0 ? 'left' : 'right'}`}
              >
                <img
                  src={coach.image.sizes.medium_large}
                  alt={coach.image.alt}
                />
              </div>
              <div className="box col-md-10 col-md-offset-1">
                <h3 className="coaches__name">{coach.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: coach.content }} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>

    <ScrollArrow scrollTo="journey" />
  </section>
);

Coaches.propTypes = {
  colorScheme: PropTypes.number,
  coaches: PropTypes.array,
  title: PropTypes.string,
  identifier: PropTypes.string,
};

Coaches.defaultProps = {
  colorScheme: 1,
  identifier: 'coaches',
};

export default Coaches;

import React from 'react';
import PropTypes from 'prop-types';

const References = ({ title, references }) => (
  <div id="references" className="references">
    <div className="container">
      <h2>{title}</h2>
      {references &&
        references.map((r, i) => (
          <div
            key={`ref-${i}`}
            className={`learning learning--${i % 2 === 0 ? 'odd' : 'even'}`}
          >
            <div>
              <h4>{r.title}</h4>
              <div dangerouslySetInnerHTML={{ __html: r.content }} />
            </div>
            <div>
              <img
                src={r.image.sizes.medium_large}
                alt={r.image.alt}
                width={r.image.sizes.medium_large_width}
                height={r.image.sizes.medium_large_height}
              />
            </div>
          </div>
        ))}
    </div>
  </div>
);

References.propTypes = {
  title: PropTypes.string,
  references: PropTypes.array,
};

export default References;

import React from 'react';
import PropTypes from 'prop-types';

const Intro = ({ background, cta, content, title }) => (
  <div
    className="intro"
    id="intro"
    style={{ backgroundImage: `url(${background.sizes.large})` }}
  >
    <div className="container intro__container">
      <hgroup>
        <h1 className="intro__title">{title}</h1>
      </hgroup>
      <div
        className="intro__details"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href={cta.url} className="cta" target={cta.target}>
          {cta.title}
        </a>
      </div>
    </div>
  </div>
);

Intro.propTypes = {
  background: PropTypes.shape({
    sizes: PropTypes.shape({
      large: PropTypes.string,
    }),
  }),
  cta: PropTypes.shape({
    target: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  content: PropTypes.string,
  title: PropTypes.string,
};

export default Intro;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ScrollArrow from './scrollArrow';

const IconPlus = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Full-Webpage--Desktop" fill="none" fillRule="evenodd">
      <g
        id="180307-FvF-Workshop_Website-Drop_Down-CLOSED"
        transform="translate(-1224 -566)"
        stroke="#FFF"
        strokeWidth="5"
      >
        <g id="Group-7" transform="translate(1224 566)">
          <g id="Group-3">
            <path d="M17.5421397,0 L17.5421397,35" id="Path-5" />
            <path d="M35,17.5 L0.0842793258,17.5" id="Path-5" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const IconMin = () => (
  <svg
    width="35"
    height="5"
    viewBox="0 0 35 5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Full-Webpage--Desktop" fill="none" fillRule="evenodd">
      <g
        id="180307-FvF-Workshop_Website-Drop_down-OPEN"
        transform="translate(-1224 -484)"
        stroke="#FFF"
        strokeWidth="5"
      >
        <g id="Group" transform="translate(824 173)">
          <g id="Group-7" transform="translate(400 312)">
            <g id="Group-3">
              <path d="M35,1.5 L0.0842793258,1.5" id="Path-5" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Rituale = ({
  box,
  title,
  content,
  image,
  features,
  title2,
  content2,
  title3,
}) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <section className="why" id="rituale">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h3 className="why__title">{title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-5">
            <div
              className="box"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-md-offset-1">
            <div className="box bleed bleed--right">
              <img src={image.sizes.medium_large} alt={image.alt} />
            </div>
            <div className="why__box">
              <div
                className="why__box-header"
                onClick={() => setCollapse(!collapse)}
              >
                <span>{box.title}</span>
                {collapse ? <IconMin /> : <IconPlus />}
              </div>
              {collapse && (
                <div
                  className="why__box-body"
                  dangerouslySetInnerHTML={{ __html: box.content }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h3 className="why__title">{title2}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div
              className="box"
              dangerouslySetInnerHTML={{ __html: content2 }}
            />
          </div>
        </div>
        <div className="hardfacts">
          <h3 className="why__title" style={{ marginBottom: 70 }}>
            {title3}
          </h3>
          <div className="hardfacts__row row between-xs">
            {features.map(
              ({ title: featureTitle, content: featureContent }) => (
                <div
                  key={`feature-${featureTitle}`}
                  className="col-xs-12 col-md-5"
                  style={{ marginBottom: 50 }}
                >
                  <div className="box">
                    <h3 className="hardfacts__headline">{featureTitle}</h3>
                    <div dangerouslySetInnerHTML={{ __html: featureContent }} />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <ScrollArrow scrollTo="coaches" />
    </section>
  );
};

Rituale.propTypes = {
  box: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  content: PropTypes.string,
  content2: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ),
  image: PropTypes.shape({
    alt: PropTypes.string,
    sizes: PropTypes.shape({
      medium_large: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
  title2: PropTypes.string,
  title3: PropTypes.string,
};

export default Rituale;

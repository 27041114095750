import React from 'react'
import PropTypes from 'prop-types'
import ScrollArrow from './scrollArrow'

const Community = ({ title, content, image }) => (
  <section className="community" id="community">
    <div className="container">
      <h2 className="community__title">{title}</h2>
      <div className="row community__row">
        <div className="col-xs-12 col-md-5">
          <div className="box" dangerouslySetInnerHTML={{ __html: content }} />
        </div>

        <div className="col-xs-10 col-md-6 col-xs-offset-1 first-xs last-md">
          <div className="box">
            <img src={image.sizes.medium_large} alt={image.alt} />
          </div>
        </div>
      </div>
    </div>

    <ScrollArrow scrollTo="contact" />
  </section>
)

Community.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    sizes: PropTypes.shape({
      medium_large: PropTypes.string,
    }),
  }),
}

export default Community

import React from 'react'
import PropTypes from 'prop-types'

const Anmeldung = ({ title, content, contact }) => (
  <section className="contact" id="contact">
    <div className="container">
      <h2 className="contact__title">{title}</h2>
      <div className="contact__row row">
        <div className="col-xs-12 col-md-6 col-md-offset-1">
          <div className="box">
            <div
              className="contact__line"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div
              className="contact__info"
              dangerouslySetInnerHTML={{ __html: contact }}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
)

Anmeldung.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  contact: PropTypes.string,
}

export default Anmeldung

import React from 'react'
import PropTypes from 'prop-types'
import chunk from 'lodash/chunk'

const JourneySection = ({
  section: {
    journey__title: title,
    journey__image: image,
    journey__color: color,
    journey__timeline: timeline,
  },
}) => (
  <div className="journey__section">
    <div className="journey__header">
      <h2 className="journey__header--title" style={{ color }}>
        {title}
      </h2>
      <img
        className="journey__header--image"
        src={image.sizes.medium_large}
        alt={image.alt}
      />
    </div>
    <div className="container">
      <div className="ruler" style={{ backgroundColor: color }}>
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          xmlns="http://www.w3.org/2000/svg"
          className="ruler__before"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-706 -8601)" stroke={color} strokeWidth="8">
              <g transform="translate(0 5659)">
                <g transform="translate(707 2946)">
                  <circle cx="13.5" cy="10.5" r="10.5" />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <svg
          width="27"
          height="20"
          viewBox="0 0 27 20"
          xmlns="http://www.w3.org/2000/svg"
          className="ruler__after"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-707 -8315)" fill={color}>
              <g transform="translate(0 5659)">
                <g transform="translate(707 1694)">
                  <polygon points="13.5 982 0 962 27 962" />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      {chunk(timeline, 2).map((row, i) => (
        <div key={`journey-row-${i}`} className="row between-xs">
          {row.map((element, j) => {
            const isOdd = j % 2 === 0
            const oddClasses =
              'journey__element journey__element--odd col-md-5 text-right'
            const evenClasses =
              'journey__element journey__element--even col-md-5'

            return (
              <div
                key={`journey-row-${i}-element-${j}`}
                className={isOdd ? oddClasses : evenClasses}
              >
                <h4 className="journey__element--title" style={{ color }}>
                  <div
                    className="journey__element--title__before"
                    style={{ backgroundColor: color }}
                  />
                  {element.title}
                </h4>
                <div dangerouslySetInnerHTML={{ __html: element.content }} />
              </div>
            )
          })}
        </div>
      ))}
    </div>
  </div>
)

JourneySection.propTypes = {
  section: PropTypes.shape({
    journey__title: PropTypes.string,
    journey__image: PropTypes.shape({
      alt: PropTypes.string,
      sizes: PropTypes.shape({
        medium_large: PropTypes.string,
      }),
    }),
    journey__color: PropTypes.string,
    journey__timeline: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      })
    ),
  }),
}

const Journey = ({ title, section }) => (
  <section className="journey" id="journey">
    <h2 className="journey__title">{title}</h2>
    {section.map((s, i) => (
      <JourneySection key={`section-${i}`} section={s} />
    ))}
  </section>
)

Journey.propTypes = {
  title: PropTypes.string,
  section: PropTypes.array,
}

export default Journey

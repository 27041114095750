import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-scroll'

const scrollArrow = ({ scrollTo }) => (
  <Link to={scrollTo} smooth className="arrow">
    <svg
      width="62"
      height="33"
      viewBox="0 0 62 33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-686 -2652)" stroke="#FFF" strokeWidth="2">
          <polyline
            transform="rotate(90 717 2668)"
            points="702 2638 732 2668 702 2698"
          />
        </g>
      </g>
    </svg>
  </Link>
)

scrollArrow.propTypes = {
  scrollTo: PropTypes.string,
}

export default scrollArrow
